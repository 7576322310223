@media screen and (min-width: 1024px) {
  .number-swiper{
    font-size: 3vmin !important;
  }
  .number-swiper-column li{
    padding: 2px 35px 0px 35px !important;
   
  }
}
.number-swiper{
  border: 5px solid #3474db;
  border-radius: 10px;
  color: #fff;
  background-color: #2253A2; 
  display:flex;
  font-size: 5vmin;
  justify-content: center;
  /* transition: 1s; */
 
 
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0));
  align-items: center;
}
/*
.number-swiper:active .number-swiper-column, .number-swiper:hover .number-swiper-column, .number-swiper:focus-within .number-swiper-column {
  box-shadow: 0 0 .1em .1em rgba(0, 0, 0, 0.5) inset;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.5));
  transition: 1s;
} */

.number-swiper-column::-webkit-scrollbar { display:none }

.number-swiper-column{
  
  overflow: scroll;
  scrollbar-width: none;
  height: 3.5em;
  /* height: 3.5em; */
  margin: 20px 0px;
  list-style: none;
  padding: 0;
  /* margin: auto 0; */
  scroll-snap-type: y mandatory;
}

.number-swiper-column li{
  padding: 0px 35px;
  scroll-snap-align: center;
  height: 1em;
  /* height: 1em; */
  margin: .25em .03em .3em .1em;
  
}
.number-swiper-active-number {
  background-color: rgba(255,255,255,0.5); 
  color: #fff;
  z-index: 111;

}
/* @charset "UTF-8"; */
.year .select-wrap {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.day .select-wrap {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.select-wrap {
  position: relative;
  height: 35%;
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  color: transparent !important;
  background-color: #2253A2;
 
}
.select-wrap:before, .select-wrap:after {
  position: absolute;
  z-index: 1;
  display: block;
  content: "";
  width: 100%;
  height: 50%;
}
.select-wrap:before {
  top: 0;
  background-image: linear-gradient(to bottom, rgba(1, 1, 1, 0.5), rgba(1, 1, 1, 0));
}
.select-wrap:after {
  bottom: 0;
  background-image: linear-gradient(to top, rgba(1, 1, 1, 0.5), rgba(1, 1, 1, 0));
}
.select-wrap .select-options {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  transform-style: preserve-3d;
  margin: 0 auto;
  display: block;
  transform: translateZ(-150px) rotateX(0deg);
  -webkit-font-smoothing: subpixel-antialiased;
  color: #fff;
}
.select-wrap .select-options .select-option {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.select-wrap .select-options .select-option:nth-child(1) {
  transform: rotateX(0deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(2) {
  transform: rotateX(-18deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(3) {
  transform: rotateX(-36deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(4) {
  transform: rotateX(-54deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(5) {
  transform: rotateX(-72deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(6) {
  transform: rotateX(-90deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(7) {
  transform: rotateX(-108deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(8) {
  transform: rotateX(-126deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(9) {
  transform: rotateX(-144deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(10) {
  transform: rotateX(-162deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(11) {
  transform: rotateX(-180deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(12) {
  transform: rotateX(-198deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(13) {
  transform: rotateX(-216deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(14) {
  transform: rotateX(-234deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(15) {
  transform: rotateX(-252deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(16) {
  transform: rotateX(-270deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(17) {
  transform: rotateX(-288deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(18) {
  transform: rotateX(-306deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(19) {
  transform: rotateX(-324deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(20) {
  transform: rotateX(-342deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(21) {
  transform: rotateX(-360deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(22) {
  transform: rotateX(-378deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(23) {
  transform: rotateX(-396deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(24) {
  transform: rotateX(-414deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(25) {
  transform: rotateX(-432deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(26) {
  transform: rotateX(-450deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(27) {
  transform: rotateX(-468deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(28) {
  transform: rotateX(-486deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(29) {
  transform: rotateX(-504deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(30) {
  transform: rotateX(-522deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(31) {
  transform: rotateX(-540deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(32) {
  transform: rotateX(-558deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(33) {
  transform: rotateX(-576deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(34) {
  transform: rotateX(-594deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(35) {
  transform: rotateX(-612deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(36) {
  transform: rotateX(-630deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(37) {
  transform: rotateX(-648deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(38) {
  transform: rotateX(-666deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(39) {
  transform: rotateX(-684deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(40) {
  transform: rotateX(-702deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(41) {
  transform: rotateX(-720deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(42) {
  transform: rotateX(-738deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(43) {
  transform: rotateX(-756deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(44) {
  transform: rotateX(-774deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(45) {
  transform: rotateX(-792deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(46) {
  transform: rotateX(-810deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(47) {
  transform: rotateX(-828deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(48) {
  transform: rotateX(-846deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(49) {
  transform: rotateX(-864deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(50) {
  transform: rotateX(-882deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(51) {
  transform: rotateX(-900deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(52) {
  transform: rotateX(-918deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(53) {
  transform: rotateX(-936deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(54) {
  transform: rotateX(-954deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(55) {
  transform: rotateX(-972deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(56) {
  transform: rotateX(-990deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(57) {
  transform: rotateX(-1008deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(58) {
  transform: rotateX(-1026deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(59) {
  transform: rotateX(-1044deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(60) {
  transform: rotateX(-1062deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(61) {
  transform: rotateX(-1080deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(62) {
  transform: rotateX(-1098deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(63) {
  transform: rotateX(-1116deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(64) {
  transform: rotateX(-1134deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(65) {
  transform: rotateX(-1152deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(66) {
  transform: rotateX(-1170deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(67) {
  transform: rotateX(-1188deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(68) {
  transform: rotateX(-1206deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(69) {
  transform: rotateX(-1224deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(70) {
  transform: rotateX(-1242deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(71) {
  transform: rotateX(-1260deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(72) {
  transform: rotateX(-1278deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(73) {
  transform: rotateX(-1296deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(74) {
  transform: rotateX(-1314deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(75) {
  transform: rotateX(-1332deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(76) {
  transform: rotateX(-1350deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(77) {
  transform: rotateX(-1368deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(78) {
  transform: rotateX(-1386deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(79) {
  transform: rotateX(-1404deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(80) {
  transform: rotateX(-1422deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(81) {
  transform: rotateX(-1440deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(82) {
  transform: rotateX(-1458deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(83) {
  transform: rotateX(-1476deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(84) {
  transform: rotateX(-1494deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(85) {
  transform: rotateX(-1512deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(86) {
  transform: rotateX(-1530deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(87) {
  transform: rotateX(-1548deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(88) {
  transform: rotateX(-1566deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(89) {
  transform: rotateX(-1584deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(90) {
  transform: rotateX(-1602deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(91) {
  transform: rotateX(-1620deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(92) {
  transform: rotateX(-1638deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(93) {
  transform: rotateX(-1656deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(94) {
  transform: rotateX(-1674deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(95) {
  transform: rotateX(-1692deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(96) {
  transform: rotateX(-1710deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(97) {
  transform: rotateX(-1728deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(98) {
  transform: rotateX(-1746deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(99) {
  transform: rotateX(-1764deg) translateZ(150px);
}
.select-wrap .select-options .select-option:nth-child(100) {
  transform: rotateX(-1782deg) translateZ(150px);
}

.highlight {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  /* background-color: #000; */
  background-color: rgba(255,255,255,0.5); 
  border-top: 1px solid rgba(255,255,255,0.5);
  border-bottom: 1px solid rgba(255,255,255,0.5);
  font-size: 24px;
  overflow: hidden;
}

.highlight-list {
  position: absolute;
  width: 100%;
}

/* date */

@media only screen and (max-width: 600px) {
  .date-selector {   
    width: 210px !important;
    
  }
}
@media only screen and (max-width: 400px) {
  .date-selector {   
    width: 200px !important;   
  }
}
@media only screen and (max-width: 360px) {
  .date-selector {   
    width: 160px !important; 
  }
}
.date-selector {
  position: absolute;
  /* left: 50%;
  top: 140%;
  transform: translate(-50%, -50%); */
  perspective: 2000px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 240px;
  height: 400px;
}
.date-selector > div {
  flex: 1;

}
/* .date-selector .select-wrap {
  font-size: 18px;
} */
.date-selector .highlight {
  font-size: 20px;
}